import { ArtifactTypes } from 'enums';
import { FIArtifact } from 'interfaces';
import React from 'react';

interface MissingArtifactListProps {
    artifactType: ArtifactTypes;
    firstEnvMissingArtifacts: FIArtifact[];
    secondEnvMissingArtifacts: FIArtifact[];
}

const MissingArtifactList: React.FC<MissingArtifactListProps> = ({ artifactType, firstEnvMissingArtifacts, secondEnvMissingArtifacts }) => (
  <div className='w-full flex flex-row justify-around border border-main-exitbutton'>
    <div className='w-full flex flex-col border-r border-main-exitbutton bg-authform-border'>
      <h3 className='h-8 w-full bg-main-header flex px-4 items-center border-b border-main-exitbutton'>
        {artifactType.charAt(0).toUpperCase() + artifactType.slice(1)}
        {' '}
        which do not exist in the first environment:
      </h3>
      <ul className='px-4 py-3'>
        {secondEnvMissingArtifacts.map((artifact: FIArtifact) => (
          <li
            key={artifact.name}
            className='h-6'
          >
            {artifact.name}
            <div>
              { artifact.content }
            </div>
          </li>
        ))}
      </ul>
    </div>
    <div className='w-full flex flex-col bg-authform-border'>
      <h3 className='h-8 w-full bg-main-header flex px-4 items-center border-b border-main-exitbutton'>
        {artifactType.charAt(0).toUpperCase() + artifactType.slice(1)}
        {' '}
        which do not exist in the second environment:
      </h3>
      <ul className='px-4 py-3'>
        {firstEnvMissingArtifacts.map((artifact: FIArtifact) => (
          <li
            key={artifact.name}
            className=''
          >
            {artifact.name}
            <div className='h-full block'>
              { artifact.content }
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>

);

export default MissingArtifactList;
