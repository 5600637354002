import { ArtifactTypes, CompareTypes } from 'enums';
import { FIArtifact } from 'interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { diffLines, formatLines } from 'unidiff';
import CompareListDifference from 'components/Compare/CompareListDifference/CompareListDifference';
import MissingArtifactList from 'components/Compare/MissingArtifactList/MissingArtifactList';

interface CompareArtifactsListProps {
    firstEnvArtifactList: FIArtifact[];
    secondEnvArtifactList: FIArtifact[];
    artifactType: ArtifactTypes;
    compareType: CompareTypes;
    onRefreshList?: Function;
    firstEnvVal?: string;
    secondEnvVal?: string;
}

const CompareArtifactsList: React.FC<CompareArtifactsListProps> = ({
  firstEnvArtifactList, secondEnvArtifactList, artifactType, compareType, onRefreshList, firstEnvVal, secondEnvVal,
}) => {
  const [firstEnvMissingArtifacts, setFirstEnvMissingArtifacts] = useState<FIArtifact[]>([]);
  const [secondEnvMissingArtifacts, setSecondEnvMissingArtifacts] = useState<FIArtifact[]>([]);
  const [firstEnvDiffArtifacts, setFirstEnvDiffArtifacts] = useState<FIArtifact[]>([]);
  const [secondEnvDiffArtifacts, setSecondEnvDiffArtifacts] = useState<FIArtifact[]>([]);

  const findDifference = useCallback(() => {
    const firstEnvMissingList: FIArtifact[] = [];
    const secondEnvMissingList: FIArtifact[] = [];
    const firstEnvSameList: FIArtifact[] = [];
    const secondEnvSameList: FIArtifact[] = [];

    firstEnvArtifactList.forEach((firstEnvArtifact) => {
      if (secondEnvArtifactList.find((secondEnvArtifact) => secondEnvArtifact.name === firstEnvArtifact.name)) {
        firstEnvSameList.push(firstEnvArtifact);
      } else {
        firstEnvMissingList.push(firstEnvArtifact);
      }
    });

    secondEnvArtifactList.forEach((secondEnvArtifact) => {
      if (firstEnvArtifactList.find((firstEnvArtifact) => firstEnvArtifact.name === secondEnvArtifact.name)) {
        secondEnvSameList.push(secondEnvArtifact);
      } else {
        secondEnvMissingList.push(secondEnvArtifact);
      }
    });

    setFirstEnvMissingArtifacts(firstEnvMissingList);
    setSecondEnvMissingArtifacts(secondEnvMissingList);

    findDiffItems(firstEnvSameList, secondEnvSameList);
  }, [firstEnvArtifactList, secondEnvArtifactList]);

  const findDiffItems = (firstEnvSameList: FIArtifact[], secondEnvSameList: FIArtifact[]) => {
    const diffListOne = firstEnvSameList.filter((firstEnvArtifact) => {
      const secondEnvSameArtifact = secondEnvSameList.find((secondEnvArtifact) => secondEnvArtifact.name === firstEnvArtifact.name);
      if (formatLines(diffLines(firstEnvArtifact.content, secondEnvSameArtifact!.content, { context: 3 }))) {
        return firstEnvArtifact;
      }
      return false;
    });
    const diffListTwo = secondEnvSameList
      .filter((artifact: FIArtifact) => diffListOne.some((anotherItem: FIArtifact) => anotherItem.name === artifact.name));

    setFirstEnvDiffArtifacts(diffListOne);
    setSecondEnvDiffArtifacts(diffListTwo);
  };

  useEffect(() => {
    findDifference();
  }, [findDifference]);

  return (
    <section className='mb-12'/* {classes['compare-artifacts-wrapper']} */>
      {firstEnvDiffArtifacts.length > 0 && (
        <div className='w-full flex flex-col justify-around border border-main-exitbutton mb-6 bg-main-header'>
          <h3 className='h-8 w-full bg-main-header flex px-4 items-center '>
            {artifactType.charAt(0).toUpperCase() + artifactType.slice(1)}
            {' '}
            with different content:
          </h3>
          <ul className=' bg-main-body m-3'>
            {firstEnvDiffArtifacts.map((item) => (
              <li
                key={item.content}
              >
                <CompareListDifference
                  name={item.name}
                  artifactType={artifactType}
                  differentItemsInFirstList={firstEnvDiffArtifacts}
                  differentItemsInSecondList={secondEnvDiffArtifacts}
                  compareType={compareType}
                  onRefreshList={onRefreshList}
                  firstEnvVal={firstEnvVal}
                  secondEnvVal={secondEnvVal}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      { (firstEnvMissingArtifacts.length > 0 || secondEnvMissingArtifacts.length > 0) && (
        <MissingArtifactList
          firstEnvMissingArtifacts={firstEnvMissingArtifacts}
          secondEnvMissingArtifacts={secondEnvMissingArtifacts}
          artifactType={artifactType}
        />
      )}
      { (firstEnvDiffArtifacts.length === 0 && firstEnvMissingArtifacts.length === 0 && secondEnvMissingArtifacts.length === 0)
      && (
      <p className='h-10 w-full bg-authform-green flex items-center justify-center'/* {classes['identical-txt']} */>
        {artifactType.charAt(0).toUpperCase() + artifactType.slice(1)}
        {' '}
        are identical!
      </p>
      )}
    </section>
  );
};

export default CompareArtifactsList;
