import React from 'react';
import { Redirect, Route } from 'react-router-dom';

type ProtectedRouteProps = {
    path: string;
    exact: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => (localStorage.getItem('jwt') ? (
  <Route
    path={props.path}
    exact={props.exact}
  >
    {props.children}
  </Route>
) : (
  <Redirect to='/' />
));

export default ProtectedRoute;
