import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from 'interfaces';
import { Dispatch } from 'redux';
import { authService } from 'services';
import { setError } from 'store/slices/error';

export const authState: AuthState = {
  loading: false,
  isAuthenticated: false,
  authenticatedUser: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    getAuthData: (state, { payload }) => {
      state.authenticatedUser = payload.authUsername;
      state.isAuthenticated = true;
      state.loading = false;
    },
    clearAuthData: (state) => {
      state.authenticatedUser = '';
      state.isAuthenticated = false;
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  getAuthData,
  clearAuthData,
} = authSlice.actions;

export const authSelector = (state: any) => state.auth;

export const autoLogin = () => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  try {
    const { authorizedUser } = await authService.autoLogin();
    dispatch(getAuthData({ authUsername: authorizedUser }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    authService.clearJwt();
    dispatch(clearAuthData());
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default authSlice.reducer;
