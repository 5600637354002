import { createSlice } from '@reduxjs/toolkit';
import { EnvState, GlobalState } from 'interfaces';
import { Dispatch } from 'redux';
import { integratorService } from 'services';
import { setError } from 'store/slices/error';

export const envState: EnvState = {
  loading: false,
  envList: [],
};

const envSlice = createSlice({
  name: 'env',
  initialState: envState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    clearEnvList: (state) => {
      state.envList = envState.envList;
    },
    setEnvList: (state, { payload }) => {
      state.loading = false;
      state.envList = payload.environments;
    },
  },
});

export const {
  setLoading, setEnvList, clearEnvList,
} = envSlice.actions;

export const envSelector = (state: GlobalState) => state.env;

export const envListSelector = (state: GlobalState) => state.env.envList;

export const getEnvList = () => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearEnvList());

  try {
    const { environments } = await integratorService.getEnvList();
    dispatch(setEnvList({ environments }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default envSlice.reducer;
