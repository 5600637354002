import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import axios from 'axios';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { authService } from 'services';
import CompareTool from 'components/Compare/CompareTool/CompareTool';
import GitConfig from 'components/GitConfig/GitConfig';
import AdminPanel from 'components/AdminPanel/AdminPanel';
import Login from 'pages/Login/Login';
import Layout from 'components/Layout/Layout';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import { authSelector, autoLogin } from 'store/slices/auth';

import './App.scss';
import ErrorComponent from 'components/Base/ErrorComponent/ErrorComponent';
import { errorSelector } from 'store/slices/error';

axios.interceptors.request.use((req) => {
  const headers: any = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    'If-Modified-Since': '0',
  };
  const jwtToken = authService.getJwt();
  if (jwtToken) {
    headers.authorization = `Bearer ${jwtToken}`;
  }

  req.headers = headers;
  return req;
});

axios.interceptors.response.use((res) => {
  const jwtToken = res.headers['jwt-token'];
  if (jwtToken) {
    authService.setJwt(jwtToken);
  }
  return res;
});

const App = () => {
  const authData = useSelector(authSelector);
  const errorData = useSelector(errorSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (authService.getJwt()) {
      dispatch(autoLogin());
    }
  }, [dispatch]);

  return (
    <>
      <Switch>
        {authData.isAuthenticated && (
        <Layout>
          <ProtectedRoute
            path='/compare-service'
            exact
          >
            <CompareTool />
          </ProtectedRoute>
          <ProtectedRoute
            path='/git-service'
            exact
          >
            <GitConfig />
          </ProtectedRoute>
          <ProtectedRoute
            path='/admin-panel'
            exact
          >
            <AdminPanel />
          </ProtectedRoute>
          <Route path='/'>
            <Redirect to='/compare-service' />
          </Route>
        </Layout>
        )}
        {!authData.isAuthenticated && (
        <>
          <Route path='/login'>
            <Login />
          </Route>
          <Route path='/'>
            <Redirect to='/login' />
          </Route>
        </>
        )}
      </Switch>
      {errorData.errorOccured && <ErrorComponent />}
    </>
  );
};
export default App;
