import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  AdminState, EnvInfo, GlobalState, UpdateEnvRequestBody,
} from 'interfaces';
import { adminService } from 'services/admin';
import { setError } from 'store/slices/error';

export const adminState: AdminState = {
  loading: false,
  envs: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: adminState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    setEnvList: (state, { payload }) => {
      state.loading = false;
      state.envs = payload.configs;
    },
  },
});

export const {
  setLoading, setEnvList,
} = adminSlice.actions;

export const adminSelector = (state: GlobalState) => state.admin;

export const getEnvList = () => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    const configs = await adminService.getEnvList();
    dispatch(setEnvList(configs));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const updateEnv = (envInfo: UpdateEnvRequestBody) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    await adminService.updateEnv(envInfo);
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const deleteEnv = (id: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    await adminService.deleteEnv(id);
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const addEnv = (newEnv: Omit<EnvInfo, 'id'>) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    await adminService.addEnv(newEnv);
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default adminSlice.reducer;
