import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineWarning } from 'react-icons/ai';

import Button from 'components/Base/Button';
import Input from 'components/Base/Input';

import { authService } from 'services';

import { getAuthData } from 'store/slices/auth';

const AuthForm: React.FC = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const initialValues = {
    username: '',
    password: '',
  };
  const [loginValues, setLoginValues] = useState(initialValues);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setLoginValues({ ...loginValues, [name]: value });
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { authUsername } = await authService.login(loginValues.username, loginValues.password);
      dispatch(getAuthData({ authUsername }));
    } catch (err: any) {
      setError(err.response.data.message);
    }
    setLoading(false);
    setLoginValues(initialValues);
  };

  return (
    <form
      onSubmit={submitHandler}
      className='border-2 border-authform-border w-96 mx-3'
    >
      <div className='flex flex-row justify-start items-center h-14 bg-authform-header p-3 border-b border-authform-border'>
        <i className='fa fa-key' />
        <span className='mx-1'>Sign In</span>
      </div>
      <div className='flex flex-col h-52 bg-authform-body px-4 pt-6'>
        <p className=''>
          { error && (
          <>
            <AiOutlineWarning />
            {error}
          </>
          )}
        </p>
        <div className='flex flex-col mb-3'>
          <label
            htmlFor='username'
            className='block pb-1'
          >
            Username
          </label>
          <Input
            placeholder='Username'
            id='username'
            name='username'
            className='border border-authform-border p-2 w-full outline-none focus:border-authform-button'
            type='text'
            value={loginValues.username}
            onChange={handleChange}
          />
        </div>
        <div className=''>
          <label
            htmlFor='password'
            className='block pb-1'
          >
            Password
          </label>
          <Input
            placeholder='Password'
            id='password'
            className='border border-authform-border p-2 w-full outline-none focus:border-authform-button'
            name='password'
            type='password'
            value={loginValues.password}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='flex w-flex-row justify-end items-center h-16 bg-authform-header border-t border-authform-border'>
        <Button
          disabled={loading || !loginValues.username || !loginValues.password}
          type='submit'
          classes='h-8 w-24 mx-3 bg-authform-button border border-authform-button-border text-authform-body hover:bg-authform-button-active'
          loading={loading}
        >
          Sign In
        </Button>
      </div>
    </form>
  );
};

export default AuthForm;
