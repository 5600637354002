import React from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from 'store/slices/auth';
import Spinner from 'components/Base/Spinner';
import MainNavigation from './MainNavigation';

import classes from './Layout.module.scss';

const Layout: React.FC = (props) => {
  const authData = useSelector(authSelector);

  return (
    <>
      {!authData.loading ? (
        <div className='flex flex-wrap'>
          <MainNavigation />
          <main className={classes.main}>{props.children}</main>
        </div>
      ) : <Spinner classes='full-height' />}
    </>
  );
};

export default Layout;
