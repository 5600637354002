import React from 'react';

interface ButtonProps {
    onClick?: (data?: any) => void;
    type?: 'button' | 'submit';
    classes?: string;
    loading?: boolean;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button', onClick, children, classes = '', loading = false, disabled = false,
}) => (
  <button
    onClick={onClick}
    type={type}
    className={`flex-wrap ${classes}`}
    disabled={disabled}
  >

    {loading && (
    <i
      className='fa fa-refresh fa-spin'
      style={{ marginRight: '5px' }}
    />
    )}
    {children}
  </button>
);

export default Button;
