import React from 'react';
import Button from 'components/Base/Button';
import { IoTrashOutline } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import classes from './ActionBtns.module.scss';
import 'styles/basic.scss';

interface ActionsButtonsProps {
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
  }

const ActionButtons: React.FC<ActionsButtonsProps> = ({ onEdit, onDelete }) => (
  <div className={classes['actions-btns']}>
    <Button
      classes='flex items-center py-2 px-2 text-sm btn btn-success'
      onClick={onEdit}
    >
      <MdOutlineModeEdit />
      Edit
    </Button>
    <Button
      classes='flex items-center  py-2 px-2 text-sm btn btn-danger'
      onClick={onDelete}
    >
      <IoTrashOutline />
      Delete
    </Button>
  </div>
);

export default ActionButtons;
