import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { BiGitMerge } from 'react-icons/bi';
import { MdOutlineCompare } from 'react-icons/md';
import { authSelector } from 'store/slices/auth';
import { RiAdminLine } from 'react-icons/ri';

const Navigation = () => {
  const authData = useSelector(authSelector);

  return (
    <nav className='w-56 bg-main-menu text-main-exitbutton'>
      <div className='h-10 border-b border-main-exitbutton flex items-center justify-start px-3'>
        <i className='fa-fw fa fa-user' />
        <span className='ml-1'>{authData.authenticatedUser}</span>
      </div>
      <ul className='w-full mt-2'>
        <li className='flex flex-row w-full justify-start items-center h-10'>
          <NavLink
            className='flex items-center justify-center p-3 hover:text-main-body'
            activeClassName=''
            to='/compare-service'
          >
            <MdOutlineCompare />
            <span className='ml-2'>Compare service</span>
          </NavLink>
        </li>
        <li className='flex flex-row w-full justify-start items-center h-10'>
          <NavLink
            className='flex items-center justify-center p-3 hover:text-main-body'
            activeClassName=''
            to='/git-service'
          >
            <BiGitMerge />
            <span className='ml-2'>Git service</span>
          </NavLink>
        </li>
        <li className='flex flex-row w-full justify-start items-center h-10 hover:text-main-body'>
          <NavLink
            className='flex items-center justify-center p-3'
            activeClassName=''
            to='/admin-panel'
          >
            <RiAdminLine />
            <span className='ml-2'>Admin panel</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
