import { combineReducers } from 'redux';
import authReducer from './auth';
import envReducer from './env';
import appReducer from './app';
import comparisonReducer from './comparison';
import gitReducer from './git';
import adminReducer from './admin';
import errorReducer from './error';

const rootReducer = combineReducers({
  auth: authReducer,
  env: envReducer,
  app: appReducer,
  git: gitReducer,
  comparison: comparisonReducer,
  admin: adminReducer,
  error: errorReducer,
});

export default rootReducer;
