import React, { useState } from 'react';

import { Plugin } from 'interfaces';
import Button from 'components/Base/Button';
import CompareConnector from './CompareConnector';

interface ComparePluginsProps {
  firstEnvPluginList: Plugin[];
  secondEnvPluginList: Plugin[];
  firstEnvVal?: string;
  secondEnvVal?: string;
}

const ComparePlugins: React.FC<ComparePluginsProps> = ({
  firstEnvPluginList, secondEnvPluginList, firstEnvVal, secondEnvVal,
}) => {
  const [firstEnvPlugins, setFirstEnvPlugins] = useState<Plugin | null>(null);
  const [secondEnvPlugins, setSecondEnvPlugins] = useState<Plugin | null>(null);
  const [pluginName, setPluginName] = useState('');
  const [active, setActive] = useState<string>();
  const selectConnector = (name: string) => {
    setPluginName(name);
    setFirstEnvPlugins(firstEnvPluginList.find((plugin: Plugin) => plugin.name === name) as Plugin);
    setSecondEnvPlugins(secondEnvPluginList.find((plugin: Plugin) => plugin.name === name) as Plugin);
  };

  return (
    <section className='bg-authform-header mb-12 border border-main-exitbutton'>
      <div className='flex flex-row justify-around items-center bg-main-header h-10 border-b border-main-exitbutton'>
        {firstEnvPluginList.map((plugin: Plugin) => (
          <Button
            classes={`h-8 w-40 flex flex-row items-center justify-around box-border ${active === plugin.name ? 'text-main-list bg-authform-button border border-authform-button-border' : ''}`}
            onClick={() => {
              selectConnector(plugin.name);
              setActive(plugin.name);
            }}
            key={plugin.name}
          >
            {plugin.name}
          </Button>
        ))}
      </div>
      { pluginName && (
      <CompareConnector
        firstEnvConnectorContent={firstEnvPlugins!.content}
        secondEnvConnectorContent={secondEnvPlugins!.content}
        firstEnvVal={firstEnvVal}
        secondEnvVal={secondEnvVal}
      />
      )}
    </section>
  );
};

export default ComparePlugins;
