import React, { forwardRef } from 'react';

type InputProps = {
  placeholder?: string;
  type: string;
  className?: string;
  name?: string;
  id?: string;
  ref?: any;
  value?: string;
  onChange?: (e?: any) => void;
  disabled?: string;
  checked?: string;
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement>((props, ref) => (
  <input
    {...props}
    ref={ref}
  />
));

export default Input;
