import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { envListSelector } from 'store/slices/env';
import {
  setConfigToGit,
  setConfigToFI,
  gitSelector,
  getBranches,
} from 'store/slices/git';
import CustomRadio from 'components/Base/CustomRadio/CustomRadio';
import Button from 'components/Base/Button';

import { appSelector, getAppList } from 'store/slices/app';
import classes from './GitConfig.module.scss';

enum ConfigType {
  INTEGRATOR = 'INTEGRATOR',
  GIT = 'GIT'
}

const GitConfig: React.FC = () => {
  const envList = useSelector(envListSelector);
  const gitData = useSelector(gitSelector);
  const appData = useSelector(appSelector);
  const dispatch = useDispatch();
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedEnv, setSelectedEnv] = useState<number>();
  const [selectedApp, setSelectedApp] = useState('');
  const [configType, setConfigType] = useState<ConfigType>();

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEnv) {
      dispatch(getAppList(selectedEnv as number));
    }
  }, [selectedEnv]);

  const setConfigToGItHandler = () => {
    setConfigType(ConfigType.GIT);
    dispatch(setConfigToGit(selectedBranch, selectedEnv!.toString(), selectedApp));
  };

  const setConfigToFIHandler = () => {
    setConfigType(ConfigType.INTEGRATOR);
    dispatch(setConfigToFI(selectedBranch, selectedEnv!.toString(), selectedApp));
  };

  return (
    <div className={classes['git-container']}>
      <div className={classes['config-wrapper']}>
        <section className='w-3/6 border border-main-exitbutton'>
          <h3 className='flex items-center h-10 px-4 bg-main-header border-main-exitbutton border-b'>Choose the environment</h3>
          <div className='bg-indigo-900'>
            {envList.map((env) => (
              <CustomRadio
                id={env.name}
                label={env.name}
                name='env'
                key={env.name}
                classNames={['env-radio-btn', 'radio-btn-label']}
                onChange={() => setSelectedEnv(env.id)}
              />
            ))}
          </div>
        </section>
        <section className='w-3/6 border-t border-r border-b border-main-exitbutton'>
          <h3 className='flex items-center h-10 px-4 bg-main-header border-main-exitbutton border-b'>Choose the branch</h3>
          <div>
            {gitData.branches.map((branch) => (
              <CustomRadio
                id={branch}
                label={branch}
                name='branch'
                key={branch}
                classNames={['env-radio-btn', 'radio-btn-label']}
                onChange={() => setSelectedBranch(branch)}
              />
            ))}
          </div>
        </section>
        {selectedEnv && !appData.loading && (
        <section className='w-full border border-main-exitbutton'>
          <h3 className='flex items-center justify-center h-10 px-4 bg-main-header border-main-exitbutton border-b'>Choose the application</h3>
          <div>
            {appData.appList && appData.appList.map((app) => (
              <CustomRadio
                id={app}
                label={app}
                name='app'
                key={app}
                classNames={['env-radio-btn', 'radio-btn-label']}
                onChange={() => setSelectedApp(app)}
              />
            ))}
          </div>

        </section>
        )}
      </div>
      {selectedEnv && (
        <section className={classes['actions-footer']}>
          <div>
            <Button
              onClick={setConfigToGItHandler}
              classes={`btn btn-success ${classes['config-btn']}`}
              loading={gitData.loading && (configType === ConfigType.GIT)}
              disabled={gitData.loading || !selectedBranch || !selectedApp}
            >
              Set config to Git repository
            </Button>
          </div>
          <div>
            <Button
              onClick={setConfigToFIHandler}
              classes={`btn btn-success ${classes['config-btn']}`}
              loading={gitData.loading && (configType === ConfigType.INTEGRATOR)}
              disabled={gitData.loading || !selectedBranch || !selectedApp}
            >
              Set config to FormsIntegrator
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

export default GitConfig;
