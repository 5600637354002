import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

import classes from './Modal.module.scss';

interface ModalProps {
  classNames?: string;
}

const Backdrop: React.FC = () => <div className={classes.backdrop} />;

const ModalOverlay: React.FC<ModalProps> = (props) => (
  <div className={classNames(classes.modal, props.classNames)}>
    {props.children}
  </div>
);

const portalElement = document.getElementById('overlays');

const Modal: React.FC<ModalProps> = (props) => (
  <>
    {portalElement && ReactDOM.createPortal(<Backdrop />, portalElement)}
    {portalElement && ReactDOM.createPortal(<ModalOverlay classNames={props.classNames}>{props.children}</ModalOverlay>, portalElement)}
  </>
);

export default Modal;
