import { createSlice } from '@reduxjs/toolkit';
import { GitState, GlobalState } from 'interfaces';
import { Dispatch } from 'redux';
import { integratorService, gitService } from 'services';
import { setError } from 'store/slices/error';

export const gitState: GitState = {
  loading: false,
  branches: [],
};

const gitSlice = createSlice({
  name: 'git',
  initialState: gitState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    setConfig: (state) => {
      state.loading = false;
    },
    clearBranches: (state) => {
      state.branches = gitState.branches;
    },
    setBranches: (state, { payload }) => {
      state.loading = false;
      state.branches = payload.branches;
    },
  },
});

export const {
  setLoading, setConfig, setBranches, clearBranches,
} = gitSlice.actions;

export const gitSelector = (state: GlobalState) => state.git;

export const setConfigToGit = (branch: string, env: string, app: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    await gitService.setConfigToGit(branch, env, app);
    dispatch(setConfig());
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const setConfigToFI = (branch: string, env: string, app: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));

  try {
    await integratorService.setConfigToIntegrator(branch, env, app);
    dispatch(setConfig());
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const getBranches = () => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearBranches());

  try {
    const branches = await gitService.getBranchesList();
    dispatch(setBranches(branches));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default gitSlice.reducer;
