import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { errorSelector, removeError } from 'store/slices/error';
import { logout } from 'store/slices/auth';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const ErrorComponent: React.FC = () => {
  const errorData = useSelector(errorSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorData.errorStatus === 401) {
      dispatch(logout());
    }
    const timer = setTimeout(() => {
      dispatch(removeError());
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={errorData.errorOccured}
        autoHideDuration={1000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          severity='error'
          variant='filled'
        >
          {errorData.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ErrorComponent;
