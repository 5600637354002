import { createSlice } from '@reduxjs/toolkit';
import { AppState, GlobalState } from 'interfaces';
import { Dispatch } from 'redux';
import { integratorService } from 'services';
import { setError } from 'store/slices/error';

export const appState: AppState = {
  loading: false,
  appList: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState: appState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    clearAppList: (state) => {
      state.appList = appState.appList;
    },
    setAppList: (state, { payload }) => {
      state.loading = false;
      state.appList = payload.apps;
    },
  },
});

export const {
  setLoading, setAppList, clearAppList,
} = appSlice.actions;

export const appSelector = (state: GlobalState) => state.app;

export const getAppList = (env: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearAppList());

  try {
    const { apps } = await integratorService.getAppsList(env);
    dispatch(setAppList({ apps }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default appSlice.reducer;
