import React, { useState } from 'react';

import Input from 'components/Base/Input';
import Button from 'components/Base/Button';
import CompareArtifactsList from 'components/Compare/CompareArtifactsList/CompareArtifactsList';
import DiffViewer from 'components/Compare/DiffViewer/DiffViewer';

import { FIArtifact, Template, Script } from 'interfaces';
import { ArtifactTypes, CompareTypes } from 'enums';

const CompareFiles: React.FC = () => {
  const [firstFileName, setFirstFileName] = useState('');
  const [secondFileName, setSecondFileName] = useState('');
  const [artifactType, setArtifactType] = useState<ArtifactTypes>();
  const [firstFileArtifactList, setFirstFileArtifactList] = useState<FIArtifact[]>([]);
  const [secondFileArtifactList, setSecondFileArtifactList] = useState<FIArtifact[]>([]);
  const [firstFileMacro, setFirstFileMacro] = useState<FIArtifact | null>(null);
  const [secondFileMacro, setSecondFileMacro] = useState<FIArtifact | null>(null);
  const [artifactTypes, setArtifactTypes] = useState<ArtifactTypes[]>([]);

  const readFiles = (files: any, target: string) => {
    let fileData = null;
    const reader = new FileReader();
    reader.onload = () => {
      fileData = JSON.parse(reader.result as any);
      if (target === 'firstFile') {
        if (fileData.templates) {
          setFirstFileArtifactList(fileData.templates.map((template: Template) => ({ content: template.content, name: template.name })));
          setFirstFileMacro({ name: 'maro', content: fileData.macro.macroContent });
          setArtifactTypes([ArtifactTypes.MACRO, ArtifactTypes.TEMPLATE]);
        } else if (fileData.scripts) {
          setFirstFileArtifactList(fileData.scripts.map((script: Script) => ({ content: script.script, name: script.name })));
          setArtifactTypes([ArtifactTypes.SCRIPT]);
        }
      }
      if (target === 'secondFile') {
        if (fileData.templates) {
          setSecondFileArtifactList(fileData.templates.map((template: Template) => ({ content: template.content, name: template.name })));
          setSecondFileMacro({ name: 'maro', content: fileData.macro.macroContent });
          setArtifactTypes([ArtifactTypes.MACRO, ArtifactTypes.TEMPLATE]);
        } else if (fileData.scripts) {
          setSecondFileArtifactList(fileData.scripts.map((script: Script) => ({ content: script.script, name: script.name })));
          setArtifactTypes([ArtifactTypes.SCRIPT]);
        }
      }
    };
    reader.readAsText(files);
  };

  const onFirstFileChange = (e: any) => {
    if (e.target.files) {
      setFirstFileName(e.target.files[0].name);
    }

    readFiles(e.target.files[0], 'firstFile');
  };

  const onSecondFileChange = (e: any) => {
    if (e.target.files) {
      setSecondFileName(e.target.files[0].name);
    }
    readFiles(e.target.files[0], 'secondFile');
  };

  return (
    <div className=''>

      <section className=''>
        <div className=''>
          <label
            htmlFor='firstEnv'
            className=''
          >
            <Input
              type='file'
              onChange={(e) => onFirstFileChange(e)}
              id='firstEnv'
              className='none'
            />
            <span>Select File</span>
          </label>
          <p>{firstFileName || 'Please select file'}</p>
        </div>

        <div className=''>
          <label
            htmlFor='secondEnv'
            className=''
          >
            <Input
              type='file'
              onChange={(e) => onSecondFileChange(e)}
              id='secondEnv'
              className='none'
            />
            <span>Select File</span>
          </label>
          <p>{secondFileName || 'Please select file'}</p>
        </div>

        <div className=''>
          {(firstFileArtifactList.length > 0 && secondFileArtifactList.length > 0)
             && artifactTypes.map((type) => (
               <Button
                 onClick={() => setArtifactType(type)}
                 classes=''
                 key={type}
               >
                 COMPARE
                 {' '}
                 {type!.toUpperCase()}
               </Button>
             ))}
        </div>
      </section>

      { (artifactType === ArtifactTypes.SCRIPT || artifactType === ArtifactTypes.TEMPLATE)
        && (firstFileArtifactList.length > 0 && secondFileArtifactList.length > 0) && (
        <CompareArtifactsList
          artifactType={artifactType}
          firstEnvArtifactList={firstFileArtifactList}
          secondEnvArtifactList={secondFileArtifactList}
          compareType={CompareTypes.FILES}
        />
      )}
      { (artifactType === ArtifactTypes.MACRO
        && firstFileMacro
         && secondFileMacro) && (
         <DiffViewer
           artifactType={ArtifactTypes.MACRO}
           firstArtifactContent={firstFileMacro!.content}
           secondArtifactContent={secondFileMacro!.content}
         />
      )}
    </div>
  );
};

export default CompareFiles;
