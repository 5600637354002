import React from 'react';
import Loader from 'react-loader-spinner';

interface SpinnerProps {
    color?: string;
    type?: 'TailSpin';
    height?: number;
    width?: number;
    classes?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  color = '#3276b1', type = 'TailSpin', height = 44, width = 100, classes = '',
}) => (
  <div className='flex items-center justify-center my-5'>
    <Loader
      type={type}
      color={color}
      height={height}
      width={width}
    />
  </div>
);

export default Spinner;
