import { ArtifactTypes } from 'enums';
import React, { useEffect, useState } from 'react';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';

import classes from './DiffViewer.module.scss';

const EMPTY_HUNKS: any = [];
interface HunksContent {
  content: string
}
interface DifferenceProps {
  type: string,
  hunks: HunksContent[]
}

interface DiffViewerProps {
  firstArtifactContent?: string;
  secondArtifactContent?: string;
  artifactType: ArtifactTypes
}

const DiffViewer: React.FC<DiffViewerProps> = ({
  firstArtifactContent, secondArtifactContent, artifactType, children,
}) => {
  const [difference, setDifference] = useState<DifferenceProps | null>(null);

  useEffect(() => {
    const isPlugin = artifactType === ArtifactTypes.PLUGIN;

    const diffText = formatLines(diffLines(isPlugin
      ? JSON.stringify(firstArtifactContent, null, 2)
      : firstArtifactContent, isPlugin ? JSON.stringify(secondArtifactContent, null, 2)
      : secondArtifactContent), { context: 3 });

    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
    setDifference(diff);
  }, [firstArtifactContent, secondArtifactContent, artifactType]);

  return (
    <div>
      {
        difference
        && (
        <div
          style={{ fontSize: '12px' }}
          className={classes['diff-viewer-container']}
        >
          <Diff
            viewType='split'
            diffType={difference.type}
            hunks={difference.hunks || EMPTY_HUNKS}
          >
            {(hunks: [{content: string}]) => hunks.map((hunk: {content: string}) => (
              <Hunk
                key={hunk.content}
                hunk={hunk}
                codeEvents={{}}
              />
            ))}
          </Diff>
          {children}
        </div>
        )
      }
      {
        difference && difference.hunks.length === 0
        && (
        <h3 style={{ textAlign: 'center' }}>
          {artifactType.charAt(0).toUpperCase() + artifactType.slice(1)}
          {' '}
          are identical
        </h3>
        )
      }
    </div>
  );
};

export default DiffViewer;
