import React from 'react';
import { useDispatch } from 'react-redux';
import { MdLogout } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { logout } from 'store/slices/auth';
import logo from 'assets/images/logo.png';
import Button from '../Base/Button';

import './Header.scss';

const Header: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logOut = async () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <header className='w-full flex felx-col justify-between items-center px-3 py-2 h-12 bg-main-header'>
      <div className=''>
        <img
          src={logo}
          alt='logo'
        />
      </div>
      <Button
        onClick={logOut}
        classes='flex justify-center items-center w-8 h-8 bg-main-body border border-main-exitbutton'
      >
        <MdLogout />
      </Button>
    </header>
  );
};

export default Header;
