import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-diff-view/style/index.css';
import DiffViewer from 'components/Compare/DiffViewer/DiffViewer';
import Button from 'components/Base/Button';
import { ArtifactTypes, CompareTypes } from 'enums';
import { FIArtifact } from 'interfaces';
import { integratorService } from 'services';
import { comparisonSelector } from 'store/slices/comparison';
import ArrowBtns from '../../Base/ArrowBtns/ArrowBtns';

interface CompareListDifferenceProps {
  name: string,
  differentItemsInFirstList: any[],
  differentItemsInSecondList: any[],
  artifactType: ArtifactTypes,
  compareType?: CompareTypes,
  onRefreshList?: Function,
  firstEnvVal?: string,
  secondEnvVal?: string
}

const CompareListDifference: React.FC<CompareListDifferenceProps> = ({
  name, differentItemsInFirstList, differentItemsInSecondList, artifactType, compareType, onRefreshList, firstEnvVal, secondEnvVal,
}) => {
  const [compareItemName, setCompareItemName] = useState(false);
  const firstItem = differentItemsInFirstList.find((item: FIArtifact) => item.name === name);
  const secondItem = differentItemsInSecondList.find((item: FIArtifact) => item.name === name);
  const comparisonData = useSelector(comparisonSelector);

  const secondArtifactChangeHandler = async () => {
    await integratorService.updateArtifact({
      name, content: firstItem.content, envToSet: comparisonData.secondEnv, type: artifactType, app: comparisonData.secondEnvApp,
    });
    if (onRefreshList) onRefreshList();
  };

  const firstArtifactChangeHandler = async () => {
    await integratorService.updateArtifact({
      name, content: secondItem.content, envToSet: comparisonData.firstEnv, type: artifactType, app: comparisonData.secondEnvApp,
    });
    if (onRefreshList) onRefreshList();
  };

  const onContentCompare = useCallback(() => {
    setCompareItemName(!compareItemName);
  }, [compareItemName]);

  return (
    <div className='bg-main-body'/* {classes['compare-content__container']} */>
      <div>
        <Button
          classes={`h-10 w-full  ${compareItemName ? 'bg-authform-green border-l border-r border-t mt-px' : 'border my-px hover:bg-authform-button'}`}
          onClick={onContentCompare}
        >
          {name}
        </Button>
      </div>
      {
        compareItemName
        && (
          <div className='border'>
            <div className='flex flex-row items-center h-7 bg-authform-green'>
              <p className='w-full border-r border-main-menu flex justify-center'>
                {`First chosen environment: ${firstEnvVal}`}
              </p>
              <p className='w-full flex justify-center'>{`Second chosen environment: ${secondEnvVal}`}</p>
            </div>
            <DiffViewer
              artifactType={artifactType}
              firstArtifactContent={artifactType === ArtifactTypes.PLUGIN ? firstItem : firstItem!.content}
              secondArtifactContent={artifactType === ArtifactTypes.PLUGIN ? secondItem : secondItem!.content}
            >
              { ((compareType === CompareTypes.API) && (artifactType === ArtifactTypes.SCRIPT || artifactType === ArtifactTypes.TEMPLATE)) && (
              <ArrowBtns
                onFirstClick={secondArtifactChangeHandler}
                onSecondClick={firstArtifactChangeHandler}
              />
              )}
            </DiffViewer>
          </div>
        )
      }
    </div>
  );
};

export default CompareListDifference;
