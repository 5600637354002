import React from 'react';
import Button from 'components/Base/Button';

import classes from './ConfirmDialog.module.scss';
import 'styles/basic.scss';

interface CondirmDialogProps {
  confirmHeader: any;
  confirmDetails: string;
  onYesClick: () => void;
  onNoClick: () => void;
}

const ConfirmDialog: React.FC<CondirmDialogProps> = ({
  confirmHeader, confirmDetails, onYesClick, onNoClick,
}) => (
  <div className={classes['confirm-dialog']}>
    <div className={classes['dialog-details']}>
      <h2 className='flex items-center gap-1 text-2xl'>{confirmHeader}</h2>
      <p>{confirmDetails}</p>
    </div>
    <div className={classes['actions-footer']}>
      <Button
        classes='btn btn-danger'
        onClick={onYesClick}
      >
        Yes
      </Button>
      <Button
        classes='btn btn-success'
        onClick={onNoClick}
      >
        No
      </Button>
    </div>
  </div>
);

export default ConfirmDialog;
