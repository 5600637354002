import { createSlice } from '@reduxjs/toolkit';
import { CompareState, GetArtifactListRequestBody, GlobalState } from 'interfaces';
import { Dispatch } from 'redux';
import { integratorService } from 'services';
import { setError } from 'store/slices/error';

export const comparisonState: CompareState = {
  firstEnv: '',
  secondEnv: '',
  firstEnvApp: '',
  secondEnvApp: '',
  firstEnvArtifactList: [],
  secondEnvArtifactList: [],
  firstEnvPluginList: [],
  secondEnvPluginList: [],
  firstEnvMacro: {
    name: '',
    content: '',
  },
  secondEnvMacro: {
    name: '',
    content: '',
  },
  firstEnvAppList: [],
  secondEnvAppList: [],
  loading: false,
};

const comparisonSlice = createSlice({
  name: 'comparison',
  initialState: comparisonState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    clearArtifactList: (state) => {
      state.firstEnvArtifactList = comparisonState.firstEnvArtifactList;
      state.secondEnvArtifactList = comparisonState.secondEnvArtifactList;
      state.firstEnv = comparisonState.firstEnv;
      state.secondEnv = comparisonState.secondEnv;
      state.firstEnvApp = comparisonState.firstEnvApp;
      state.secondEnvApp = comparisonState.secondEnvApp;
    },
    setArtifactList: (state, { payload }) => {
      state.firstEnvArtifactList = payload.firstEnvArtifactList;
      state.secondEnvArtifactList = payload.secondEnvArtifactList;
      state.firstEnv = payload.firstEnv;
      state.secondEnv = payload.secondEnv;
      state.firstEnvApp = payload.firstApp;
      state.secondEnvApp = payload.secondApp;
      state.loading = false;
    },
    clearMacros: (state) => {
      state.firstEnvMacro = comparisonState.firstEnvMacro;
      state.secondEnvMacro = comparisonState.secondEnvMacro;
      state.firstEnv = comparisonState.firstEnv;
      state.secondEnv = comparisonState.secondEnv;
      state.firstEnvApp = comparisonState.firstEnvApp;
      state.secondEnvApp = comparisonState.secondEnvApp;
    },
    setMacros: (state, { payload }) => {
      state.firstEnvMacro = payload.firstEnvMacro;
      state.secondEnvMacro = payload.secondEnvMacro;
      state.firstEnv = payload.firstEnv;
      state.secondEnv = payload.secondEnv;
      state.firstEnvApp = payload.firstApp;
      state.secondEnvApp = payload.secondApp;
      state.loading = false;
    },
    clearPluginList: (state) => {
      state.firstEnvPluginList = comparisonState.firstEnvPluginList;
      state.secondEnvPluginList = comparisonState.secondEnvPluginList;
      state.firstEnv = comparisonState.firstEnv;
      state.secondEnv = comparisonState.secondEnv;
      state.firstEnvApp = comparisonState.firstEnvApp;
      state.secondEnvApp = comparisonState.secondEnvApp;
    },
    setPluginList: (state, { payload }) => {
      state.firstEnvPluginList = payload.firstEnvPluginList;
      state.secondEnvPluginList = payload.secondEnvPluginList;
      state.firstEnv = payload.firstEnv;
      state.secondEnv = payload.secondEnv;
      state.firstEnvApp = payload.firstApp;
      state.secondEnvApp = payload.secondApp;
      state.loading = false;
    },
    clearFirstEnvAppList: (state) => {
      state.firstEnvAppList = comparisonState.firstEnvAppList;
    },
    setFirstEnvAppList: (state, { payload }) => {
      state.firstEnvAppList = payload.apps;
      state.loading = false;
    },
    clearSecondEnvAppList: (state) => {
      state.secondEnvAppList = comparisonState.secondEnvAppList;
    },
    setSecondEnvAppList: (state, { payload }) => {
      state.secondEnvAppList = payload.apps;
      state.loading = false;
    },
  },
});

export const {
  setArtifactList,
  setMacros,
  setLoading,
  setFirstEnvAppList,
  setSecondEnvAppList,
  setPluginList,
  clearFirstEnvAppList,
  clearSecondEnvAppList,
  clearArtifactList,
  clearPluginList,
  clearMacros,
} = comparisonSlice.actions;

export const comparisonSelector = (state: GlobalState) => state.comparison;

export const getArtifacts = (body: GetArtifactListRequestBody) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearArtifactList());

  try {
    const response = await integratorService.getArtifacts(body);
    dispatch(setArtifactList({
      ...response, firstEnv: body.firstEnvironment, secondEnv: body.secondEnvironment, firstApp: body.firstApp, secondApp: body.secondApp,
    }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const getMacros = (firstEnv: number, secondEnv: number, firstApp: string, secondApp: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearMacros());

  try {
    const response = await integratorService.getMacros(firstEnv, secondEnv, firstApp, secondApp);
    dispatch(setMacros({
      ...response, firstEnv, secondEnv, firstApp, secondApp,
    }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const getPlugins = (firstEnv: number, secondEnv: number, firstApp: string, secondApp: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearPluginList());

  try {
    const response = await integratorService.getPlugins(firstEnv, secondEnv, firstApp, secondApp);
    dispatch(setPluginList({
      ...response, firstEnv, secondEnv, firstApp, secondApp,
    }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const getFirstEnvAppsList = (env: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearFirstEnvAppList());

  try {
    const { apps } = await integratorService.getAppsList(env);
    dispatch(setFirstEnvAppList({ apps }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export const getSecondEnvAppsList = (env: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading({ loading: true }));
  dispatch(clearSecondEnvAppList());

  try {
    const { apps } = await integratorService.getAppsList(env);
    dispatch(setSecondEnvAppList({ apps }));
  } catch (err: any) {
    dispatch(setLoading({ loading: false }));
    dispatch(setError({ message: err.response.data.message, errorStatus: err.response.status }));
  }
};

export default comparisonSlice.reducer;
