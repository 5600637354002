import { createSlice } from '@reduxjs/toolkit';
import { ErrorState, GlobalState } from 'interfaces';

export const errorState: ErrorState = {
  errorOccured: false,
  errorMessage: '',
  errorStatus: 200,
};

const errorSlice = createSlice({
  name: 'env',
  initialState: errorState,
  reducers: {
    setError: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.errorOccured = true;
      state.errorStatus = payload.errorStatus;
    },
    removeError: (state) => {
      state.errorOccured = false;
      state.errorMessage = '';
      state.errorStatus = 200;
    },
  },
});

export const {
  setError, removeError,
} = errorSlice.actions;

export const errorSelector = (state: GlobalState) => state.error;

export default errorSlice.reducer;
