import Button from 'components/Base/Button';
import React from 'react';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';

import classes from './ArrowBtns.module.scss';

interface ArrowBtnsProps {
    onFirstClick: () => Promise<void>;
    onSecondClick: () => Promise<void>;
}

const ArrowBtns: React.FC<ArrowBtnsProps> = ({ onFirstClick, onSecondClick }) => (
  <div className={classes['move-btns']}>
    <Button
      classes={classes['move-btn']}
      onClick={onFirstClick}
    >
      <ImArrowRight />
    </Button>
    <Button
      classes={classes['move-btn']}
      onClick={onSecondClick}
    >
      <ImArrowLeft />
    </Button>
  </div>
);

export default ArrowBtns;
