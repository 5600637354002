import React, { ChangeEvent } from 'react';
import Input from 'components/Base/Input';

import './CustomRadio.scss';

interface CustomRadioProps {
  id: string
  label: string;
  onChange: (e?: ChangeEvent) => void;
  name: string;
  classNames: string[];
  disabled?: string;
  checked?: string;
}

const CustomRadio: React.FC<CustomRadioProps> = (props) => (
  <div className='flex align-center justify-center'>
    <Input
      type='radio'
      className={props.classNames[0]}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      disabled={props.disabled}
      checked={props.checked}
    />
    <label
      htmlFor={props.id}
      className={props.classNames[1]}
    >
      {props.label.charAt(0).toUpperCase() + props.label.slice(1)}
    </label>
  </div>

);

export default CustomRadio;
