const api = process.env.REACT_APP_BASE_URL;

export const Api = {
  // GIT
  GET_BRANCHES_LIST: `${api}/git/branches`,
  SET_CONFIG_TO_GIT: `${api}/git/config/set`,
  // FI
  SET_CONFIG_TO_INTEGRATOR: `${api}/integrator/config/set`,
  GET_APPS_LIST: `${api}/integrator/apps/list`,
  GET_FI_TEMPLATES: `${api}/integrator/templates`,
  GET_FI_MACROS: `${api}/integrator/macros`,
  GET_FI_SCRIPTS: `${api}/integrator/scripts`,
  GET_FI_PLUGINS: `${api}/integrator/plugins`,
  SET_FI_ARTIFACT: `${api}/integrator/artifact/set`,
  UPDATE_FI_ARTIFACT: `${api}/integrator/artifact/update`,
  GET_ENV_LIST: `${api}/integrator/env/list`,
  GET_FI_ARTIFACTS: `${api}/integrator/artifacts`,

  // AUTH
  LOGIN: `${api}/auth/login`,
  AUTO_LOGIN: `${api}/auth/auto-login`,

  //  ADMIN
  INTEGRATOR_CONFIGS: `${api}/admin/env/list`,
  UPDATE_INTEGRATOR_CONFIG: `${api}/admin/env`,
  DELETE_INTEGRATOR_CONFIG: `${api}/admin/env`,
  ADD_INTEGRATOR_CONFIG: `${api}/admin/env`,
};
