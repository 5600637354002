import axios from 'axios';
import { Api } from '../constants/urls';

const login = async (username: string, password: string) => {
  const authData = await axios.post(Api.LOGIN, {
    username,
    password,
  });

  return authData.data;
};

const autoLogin = async () => {
  const authData = await axios.post(Api.AUTO_LOGIN);
  return authData.data;
};

const setJwt = (jwt: string): void => {
  localStorage.setItem('jwt', jwt);
};

const getJwt = (): string | null => localStorage.getItem('jwt');

const clearJwt = (): void => {
  localStorage.removeItem('jwt');
};

export const authService = {
  setJwt,
  getJwt,
  clearJwt,
  login,
  autoLogin,
};
