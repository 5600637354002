import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEnvList,
  adminSelector,
  updateEnv,
  deleteEnv,
  addEnv,
} from 'store/slices/admin';
import { BiRefresh } from 'react-icons/bi';
import DataTable from 'components/DataTable/DataTable';
import Modal from 'components/Base/Modal/Modal';
import ActionButtons from 'components/Base/ActionsBtns/ActionBtns';
import { EnvInfo, UpdateEnvRequestBody } from 'interfaces';

import Input from 'components/Base/Input';
import Button from 'components/Base/Button';
import { FiSave } from 'react-icons/fi';
import classNames from 'classnames';
import ConfirmDialog from 'components/Base/ConfirmDialog/ConfirmDialog';
import { AiOutlineWarning } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';

import classes from './AdminPanel.module.scss';
import 'styles/basic.scss';

enum ModalType {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add',
}

const AdminPanel: React.FC = () => {
  const dispatch = useDispatch();
  const adminData = useSelector(adminSelector);
  const [showModal, setShowModal] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState<EnvInfo>();
  const [modalType, setModalType] = useState<ModalType>();

  const initialFormValues = {
    env: '', link: '', username: '', password: '',
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'env',
      },
      {
        Header: 'Link',
        accessor: 'link',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell }: any) => (
          <ActionButtons
            onEdit={() => onEditClick(cell.row.original)}
            onDelete={() => onDeleteClick(cell.row.original)}
          />
        ),
      },
    ], [],
  );

  const formInputs = [
    {
      label: 'Name',
      name: 'env',
    },
    {
      label: 'Link',
      name: 'link',
    },
    {
      label: 'Username',
      name: 'username',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
    },
  ];

  useEffect(() => {
    dispatch(getEnvList());
  }, []);

  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onEditClick = (env: EnvInfo) => {
    setSelectedEnv(env);
    setFormValues(env);
    setShowModal(true);
    setModalType(ModalType.EDIT);
  };

  const onAddClick = () => {
    setFormValues(initialFormValues);
    setModalType(ModalType.ADD);
    setShowModal(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  const validate = (values: any) => {
    const errors: any = {};
    for (const key in values) {
      if (!values[key]) {
        errors[key] = 'Cannot be blank';
      }
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      if (modalType === ModalType.EDIT) {
        editHandler();
      } else if (modalType === ModalType.ADD) {
        addEnvHandler();
      }
    }
  }, [formErrors, isSubmitting, showModal]);

  const addEnvHandler = () => {
    dispatch(addEnv(formValues));
    dispatch(getEnvList());
    setShowModal(false);
    setFormErrors({});
    setIsSubmitting(false);
  };

  const editHandler = () => {
    const requestBody: UpdateEnvRequestBody = {
      env: formValues.env,
      link: formValues.link,
      username: formValues.username,
      id: selectedEnv!.id,
    };
    if (selectedEnv!.password !== formValues.password) {
      requestBody.password = formValues.password;
    }
    dispatch(updateEnv(requestBody));
    dispatch(getEnvList());
    setShowModal(false);
    setFormErrors({});
    setIsSubmitting(false);
  };

  const onDeleteClick = (env: EnvInfo) => {
    setSelectedEnv(env);
    setModalType(ModalType.DELETE);
    setShowModal(true);
  };

  return (
    <div className='w-full bg-main-list border border-main-exitbutton'>
      <div className='flex w-full justify-between items-center h-12 px-6 border-b border-main-exitbutton'>
        <Button
          onClick={() => dispatch(getEnvList())}
          classes='outline-none text-4xl bg-authform-button text-main-list rounded border border-authform-button-border btn-primary btn-primary:hover'
        >
          <BiRefresh />
        </Button>
        <Button
          classes='h-9 w-40 flex flex-row items-center justify-around text-main-list bg-authform-button border border-authform-button-border btn-primary btn-primary:hover'
          onClick={onAddClick}
        >
          <FaPlus />
          New Environment
        </Button>
      </div>
      <div className='flex flex-col'>
        <DataTable
          columns={columns}
          data={adminData.envs}
          classes=''
        />
      </div>
      {showModal && (
        <Modal classNames={classes.modal}>

          { (modalType === ModalType.EDIT || modalType === ModalType.ADD) && (
          <form className='flex flex-col rounded-xl m-5 font-extralight border-solid-[#ddd]'>

            <div className='font-light text-lg border-b-2 border-solid'>
              <span>Environment</span>
            </div>

            <div className='my-3'>

              {formInputs.map((formInput) => (
                <div
                  className='form-group'
                  key={formInput.name}
                >
                  <label htmlFor='env'>{formInput.label}</label>
                  <Input
                    id={formInput.name}
                    name={formInput.name}
                    type={formInput.type || 'text'}
                    value={(formValues as any)[`${formInput.name}`]}
                    onChange={handleChange}
                    className={classNames('input',
                      {
                        'input-error': formErrors[`${formInput.name}`],
                      })}
                  />
                  {formErrors[`${formInput.name}`] && (
                  <span className='error'>{formErrors[`${formInput.name}`]}</span>
                  )}
                </div>
              ))}
            </div>

            <div className='flex items-center justify-between'>
              <div className=''>
                <Button
                  classes='btn btn-success flex items-center gap-1'
                  onClick={handleSubmit}
                >
                  <FiSave />
                  Save
                </Button>
              </div>
              <div>
                <Button
                  classes='btn btn-danger'
                  onClick={() => {
                    setShowModal(false);
                    setFormErrors({});
                    setIsSubmitting(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
          )}
          {(modalType === ModalType.DELETE) && (
            <ConfirmDialog
              onNoClick={() => setShowModal(false)}
              onYesClick={() => {
                dispatch(deleteEnv(selectedEnv!.id.toString()));
                dispatch(getEnvList());
                setShowModal(false);
              }}
              confirmDetails={`Are you sure you want to delete ${selectedEnv!.env} environment?`}
              confirmHeader={(
                <>
                  <AiOutlineWarning className='warning-icon' />
                  Are you sure?
                </>
              )}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default AdminPanel;
