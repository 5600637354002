import CustomRadio from 'components/Base/CustomRadio/CustomRadio';
import { ArtifactTypes, CompareTypes } from 'enums';
import { Env } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { envListSelector, getEnvList } from 'store/slices/env';
import {
  comparisonSelector,
  getArtifacts,
  getFirstEnvAppsList,
  getMacros,
  getPlugins,
  getSecondEnvAppsList,
} from 'store/slices/comparison';
import Button from 'components/Base/Button';
import Spinner from 'components/Base/Spinner';
import CompareArtifactsList from 'components/Compare/CompareArtifactsList/CompareArtifactsList';
import ComparePlugins from 'components/Compare/ComparePlugins/ComparePlugins';
import DiffViewer from 'components/Compare/DiffViewer/DiffViewer';

const CompareAPI: React.FC = () => {
  const dispatch = useDispatch();
  const compareData = useSelector(comparisonSelector);
  const [artifactType, setArtifactType] = useState<ArtifactTypes>();
  const artifactTypes = [ArtifactTypes.TEMPLATE, ArtifactTypes.SCRIPT, ArtifactTypes.PLUGIN, ArtifactTypes.MACRO];
  const [firstEnvId, setFirstEnv] = useState<number>();
  const [secondEnvId, setSecondEnv] = useState<number>();
  const [firstEnvName, setFirstEnvName] = useState('');
  const [secondEnvName, setSecondEnvName] = useState('');
  const [firstApp, setFirstApp] = useState('');
  const [secondApp, setSecondApp] = useState('');
  const [active, setActive] = useState<string>();

  useEffect(() => {
    if (firstEnvId) {
      dispatch(getFirstEnvAppsList(firstEnvId));
    }
  }, [firstEnvId]);

  useEffect(() => {
    if (secondEnvId) {
      dispatch(getSecondEnvAppsList(secondEnvId));
    }
  }, [secondEnvId]);

  const environments: Env[] = useSelector(envListSelector);

  const onArtifactTypeChange = (artifactType: ArtifactTypes) => {
    setArtifactType(artifactType);
    switch (artifactType) {
      case ArtifactTypes.TEMPLATE:
        dispatch(getArtifacts({
          firstEnvironment: firstEnvId as number, secondEnvironment: secondEnvId as number, firstApp, secondApp, artifactType,
        }));
        break;
      case ArtifactTypes.SCRIPT:
        dispatch(getArtifacts({
          firstEnvironment: firstEnvId as number, secondEnvironment: secondEnvId as number, firstApp, secondApp, artifactType,
        }));
        break;
      case ArtifactTypes.PLUGIN:
        dispatch(getPlugins(firstEnvId as number, secondEnvId as number, firstApp, secondApp));
        break;
      case ArtifactTypes.MACRO:
        dispatch(getMacros(firstEnvId as number, secondEnvId as number, firstApp, secondApp));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    dispatch(getEnvList());
  }, [dispatch]);

  return (
    <div className='w-full'>
      <section className='w-full flex flex-col'>
        <div className='w-full flex flex-row justify-around items-center border border-main-exitbutton'>
          <div className='w-full'>
            <p className='h-10 px-4 border-b flex items-center border-r border-main-exitbutton bg-main-header'>Choose first environment</p>
            <div
              className='flex flex-row h-16 border-r justify-around items-center border-main-exitbutton'
            >
              {environments.map((firstEnv) => (
                <CustomRadio
                  id={`${firstEnv.id}firstEnv`}
                  name='firstEnv'
                  onChange={() => {
                    setFirstEnv(firstEnv.id);
                    // eslint-disable-next-line no-unused-expressions
                    firstEnvName === '' ? setFirstEnvName(firstEnv.name) : setFirstEnvName('');
                  }}
                  label={firstEnv.name}
                  classNames={['env-radio-btn', 'env-radio-btn-label']}
                  key={`${firstEnv.id}firstEnv`}
                  disabled={firstEnv.name === secondEnvName ? 'disabled' : ''}
                  checked={firstEnvName === firstEnv.name ? 'checked' : ''}
                />
              ))}
            </div>
          </div>
          <div className='w-full'>
            <p className='h-10 px-4 border-b flex items-center border-main-exitbutton bg-main-header'>Choose second environment</p>
            <div
              className='flex flex-row h-16 justify-around items-center'
            >
              {environments.map((secondEnv) => (
                <CustomRadio
                  id={`${secondEnv.id}secondEnv`}
                  name='secondEnv'
                  onChange={() => {
                    setSecondEnv(secondEnv.id);
                    // eslint-disable-next-line no-unused-expressions
                    secondEnvName === '' ? setSecondEnvName(secondEnv.name) : setSecondEnvName('');
                  }}
                  label={secondEnv.name}
                  classNames={['env-radio-btn', 'env-radio-btn-label']}
                  key={`${secondEnv.id}secondEnv`}
                  disabled={secondEnv.name === firstEnvName ? 'disbled' : ''}
                  checked={secondEnvName === secondEnv.name ? 'checked' : ''}
                />
              ))}
            </div>
          </div>
        </div>
        { firstEnvId && secondEnvId && (
        <div className='w-full flex flex-row justify-around mt-7 border border-main-exitbutton '>
          <div className='w-full border-r border-main-exitbutton'>
            <p className=' h-10 px-4 border-b flex items-center border-main-exitbutton bg-main-header'>Choose application:</p>
            <div
              className='w-full'
            >
              {compareData.firstEnvAppList.map((app) => (
                <CustomRadio
                  id={`${app}firstEnv`}
                  name='appfirstEnv'
                  onChange={() => setFirstApp(app)}
                  label={app}
                  classNames={['app-radio-btn', 'app-radio-btn-label']}
                  key={`${app}firstEnv`}
                />
              ))}
            </div>
          </div>
          <div className='w-full'>
            <p className=' h-10 px-4 border-b flex items-center border-main-exitbutton bg-main-header'>Choose application:</p>
            <div
              className='w-full border-l border-main-body'
            >
              {compareData.secondEnvAppList.map((app) => (
                <CustomRadio
                  id={`${app}secondEnv`}
                  name='appsecondEnv'
                  onChange={() => setSecondApp(app)}
                  label={app}
                  classNames={['app-radio-btn', 'app-radio-btn-label']}
                  key={`${app}secondEnv`}
                />
              ))}
            </div>
          </div>
        </div>
        )}

        { (firstApp && secondApp)
        && (
        <div className='mt-4 mb-9 w-full h-14 flex flex-row justify-around items-center'>
          {artifactTypes.map((type) => (
            <Button
              onClick={() => {
                onArtifactTypeChange(type);
                setActive(type);
              }}
              classes={`h-8 w-44 bg-main-exitbutton hover:bg-authform-button-border ${active === type ? 'bg-authform-button' : ''}`}
              key={type}
            >
              COMPARE
              {' '}
              {type.toUpperCase()}
            </Button>
          ))}
        </div>
        )}
      </section>

      { (artifactType === ArtifactTypes.SCRIPT || artifactType === ArtifactTypes.TEMPLATE)
         && !compareData.loading
          && (
          <CompareArtifactsList
            firstEnvArtifactList={compareData.firstEnvArtifactList}
            secondEnvArtifactList={compareData.secondEnvArtifactList}
            artifactType={artifactType}
            compareType={CompareTypes.API}
            onRefreshList={() => onArtifactTypeChange(artifactType)}
            firstEnvVal={firstEnvName}
            secondEnvVal={secondEnvName}
          />
          )}
      { artifactType === ArtifactTypes.MACRO
         && !compareData.loading
          && (
          <DiffViewer
            artifactType={artifactType}
            firstArtifactContent={compareData.firstEnvMacro.content}
            secondArtifactContent={compareData.secondEnvMacro.content}
          />
          )}
      { artifactType === ArtifactTypes.PLUGIN
         && !compareData.loading
          && (
            <ComparePlugins
              firstEnvPluginList={compareData.firstEnvPluginList}
              secondEnvPluginList={compareData.secondEnvPluginList}
              firstEnvVal={firstEnvName}
              secondEnvVal={secondEnvName}
            />
          )}
      { (artifactType && compareData.loading) && <Spinner />}
    </div>
  );
};

export default CompareAPI;
