import axios from 'axios';
import { EnvInfo, UpdateEnvRequestBody } from 'interfaces';
import { Api } from '../constants/urls';

const getEnvList = async (): Promise<{ configs: EnvInfo[] }> => {
  const response = await axios.get(Api.INTEGRATOR_CONFIGS);

  return response.data;
};

const updateEnv = async (envInfo: UpdateEnvRequestBody): Promise<void> => {
  await axios.put(Api.UPDATE_INTEGRATOR_CONFIG, envInfo);
};

const deleteEnv = async (id: string): Promise<void> => {
  await axios.delete(Api.DELETE_INTEGRATOR_CONFIG, {
    params: {
      id,
    },
  });
};

const addEnv = async (newEnv: Omit<EnvInfo, 'id'>): Promise<void> => {
  await axios.post(Api.ADD_INTEGRATOR_CONFIG, newEnv);
};

export const adminService = {
  getEnvList,
  updateEnv,
  deleteEnv,
  addEnv,
};
