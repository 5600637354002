import classNames from 'classnames';
import React from 'react';
import { useTable, Column } from 'react-table';

interface DataTableProps {
    columns: Column[];
    data: any;
    classes: string
}

const DataTable: React.FC<DataTableProps> = ({ columns, data, classes }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  );

  return (
    <>
      <table
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <th className={classNames('text-left border-main-exitbutton bg-main-header border-b h-10 px-6',
                  {
                    'text-left border-r border-main-exitbutton bg-main-header h-10 px-6': index !== headerGroup.headers.length - 1,
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => (
                  <td
                    className={classNames('text-left border-b border-main-exitbutton h-10',
                      {
                        'text-left border-b border-r border-main-exitbutton h-10 pl-6': index !== row.cells.length - 1,
                      })}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default DataTable;
