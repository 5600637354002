import React from 'react';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';

const MainNavigation: React.FC = () => (
  <>
    <Header />
    <Navigation />
  </>
);

export default MainNavigation;
