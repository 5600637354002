import React from 'react';
import AuthForm from '../../components/Auth/AuthForm';
// import classes from './Login.module.scss';

const Login = () => (
  <div className='absolute right-0 h-full w-full mx-auto bg-main-image bg-100% resize-none bg-no-repeat flex items-center justify-center'/* {classes['login-wrapper']} */>
    <AuthForm />
  </div>
);

export default Login;
