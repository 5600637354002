import React, { useState } from 'react';
import classnames from 'classnames';

import { CompareTypes } from 'enums';
import Button from 'components/Base/Button';
import CompareAPI from 'components/Compare/CompareAPI/CompareAPI';
import CompareFiles from 'components/Compare/CompareFIles/CompareFiles';

const CompareTool = () => {
  const [compareType, setCompareType] = useState<CompareTypes>(CompareTypes.API);
  const [buttonsState, setButtonsState] = useState({
    useApiButton: true,
    importFilesButton: false,
  });
  return (
    <div className='w-full'>
      <div className='w-full h-14 flex flex-row items-center justify-between cursor-pointer border border-authform-border bg-main-body'>
        <div className={`w-1/2 flex items-center justify-center h-full bg-main-exitbutton hover:bg-main-menu hover:text-main-body mr-1 ${buttonsState.useApiButton ? 'bg-main-menu text-main-body' : ''}`}>
          <Button
            onClick={() => {
              setCompareType(CompareTypes.API);
              setButtonsState({
                useApiButton: true,
                importFilesButton: false,
              });
            }}
            classes={classnames('w-full h-full', {
              active: compareType === CompareTypes.API,
            })}
          >
            Use API
          </Button>
        </div>
        <div className={`w-1/2 flex items-center justify-center h-full bg-main-exitbutton hover:bg-main-menu hover:text-main-body ml-1 ${buttonsState.importFilesButton ? 'bg-main-menu text-main-body' : ''}`}>
          <Button
            onClick={() => {
              setCompareType(CompareTypes.FILES);
              setButtonsState({
                useApiButton: false,
                importFilesButton: true,
              });
            }}
            classes={classnames('w-full h-full', {
              active: compareType === CompareTypes.FILES,
            })}
          >
            Import Files
          </Button>
        </div>
      </div>
      <div className='w-full flex items-center justify-center mt-12'>
        {compareType === CompareTypes.API && <CompareAPI />}
        {compareType === CompareTypes.FILES && <CompareFiles />}
      </div>
    </div>
  );
};

export default CompareTool;
