import React, { useCallback, useEffect, useState } from 'react';
import { diffLines, formatLines } from 'unidiff';

import { Endpoint, FIProperty, PluginContent } from 'interfaces';
import CompareListDifference from 'components/Compare/CompareListDifference/CompareListDifference';
import MissingArtifactList from 'components/Compare/MissingArtifactList/MissingArtifactList';
import { ArtifactTypes } from 'enums';

interface CompareConnectorProps {
  firstEnvConnectorContent: PluginContent,
  secondEnvConnectorContent: PluginContent,
  firstEnvVal?: string;
  secondEnvVal?: string;
}

const CompareConnector: React.FC<CompareConnectorProps> = ({
  firstEnvConnectorContent, secondEnvConnectorContent, firstEnvVal, secondEnvVal,
}) => {
  const [firstEnvMissingEndpoints, setfirstEnvMissingEndpoints] = useState([]);
  const [secondEnvMissingEndpoints, setsecondEnvMissingEndpoints] = useState([]);
  const [firstEnvDiffEndpoints, setfirstEnvDiffEndpoints] = useState<Endpoint[] | []>([]);
  const [secondEnvDiffEndpoints, setsecondEnvDiffEndpoints] = useState<Endpoint[] | []>([]);

  const [missingPropertiesInfirstIntegratorFile, setMissingPropertiesInFirstIntegratorFile] = useState([]);
  const [missingPropertiesInsecondIntegratorFile, setMissingPropertiesInSecondIntegratorFile] = useState([]);

  const findDifferentEndpoints = useCallback((firstEnvList, secondEnvList, setMissingEndpointsInListOne, setMissingEndpointsInListTwo) => {
    const missingEndpointsInSecondEnv: Endpoint[] = [];
    const missingEndpointsInFirstEnv: Endpoint[] = [];
    const firstEnvSameEndpoints: Endpoint[] = [];
    const secondEnvSameEndpoints: Endpoint[] = [];

    firstEnvList.forEach((endpoint: Endpoint) => {
      if (secondEnvList.find((secondEnvEndpoint: Endpoint) => secondEnvEndpoint.name === endpoint.name)) {
        firstEnvSameEndpoints.push(endpoint);
      } else {
        missingEndpointsInSecondEnv.push(endpoint);
      }
    });

    secondEnvList.forEach((endpoint: Endpoint) => {
      if (firstEnvList.find((firstEnvEndpoint: Endpoint) => firstEnvEndpoint.name === endpoint.name)) {
        secondEnvSameEndpoints.push(endpoint);
      } else {
        missingEndpointsInFirstEnv.push(endpoint);
      }
    });

    setMissingEndpointsInListTwo(firstEnvMissingEndpoints);

    setMissingEndpointsInListOne(secondEnvMissingEndpoints);
    findDiffEndpoints(firstEnvSameEndpoints, secondEnvSameEndpoints);
  }, []);

  const findDiffEndpoints = (sameListOne: Endpoint[], sameListTwo: Endpoint[]) => {
    const diffListOne = sameListOne.filter((item: Endpoint) => {
      const sameItemFromsecondList = sameListTwo.find((secondItem: Endpoint) => secondItem.name === item.name);
      if (formatLines(diffLines(JSON.stringify(item), JSON.stringify(sameItemFromsecondList), { context: 3 }))) {
        return item;
      }
      return false;
    });
    setfirstEnvDiffEndpoints(diffListOne);

    const diffListTwo = sameListTwo.filter((item: Endpoint) => diffListOne.some((anotherItem: Endpoint) => anotherItem.name === item.name));
    setsecondEnvDiffEndpoints(diffListTwo);
  };

  const findDifferentProperties = (
    firstProperties: FIProperty,
    secondProperties: FIProperty,
    setMissingPropertiesInListOne: Function,
    setMissingPropertiesInListTwo: Function,
  ) => {
    const firstPropertiesNames = Object.keys(firstProperties);
    const secondPropertiesNames = Object.keys(secondProperties);

    const missingPropertiesInListOne = secondPropertiesNames
      .filter((secondEnvProperty) => !firstPropertiesNames.some((firstEnvProperty) => firstEnvProperty === secondEnvProperty));

    const missingPropertiesInListTwo = firstPropertiesNames
      .filter((firstEnvProperty) => !secondPropertiesNames.some((secondEnvProperty) => secondEnvProperty === firstEnvProperty));

    setMissingPropertiesInListOne(missingPropertiesInListOne);
    setMissingPropertiesInListTwo(missingPropertiesInListTwo);
  };

  useEffect(() => {
    findDifferentEndpoints(
      firstEnvConnectorContent.endpoints,
      secondEnvConnectorContent.endpoints,
      setfirstEnvMissingEndpoints,
      setsecondEnvMissingEndpoints,
    );

    if (firstEnvConnectorContent.properties) {
      findDifferentProperties(
        firstEnvConnectorContent.properties,
        (secondEnvConnectorContent as any).properties,
        setMissingPropertiesInFirstIntegratorFile,
        setMissingPropertiesInSecondIntegratorFile,
      );
    }
  }, [
    findDifferentEndpoints,
    firstEnvConnectorContent.endpoints,
    firstEnvConnectorContent.properties,
    secondEnvConnectorContent.endpoints,
    secondEnvConnectorContent.properties,
    secondEnvConnectorContent,
  ]);
  return (
    <div className=''>
      {firstEnvDiffEndpoints.length > 0 && (
      <div className='flex flex-col justify-around pb-6 px-6 bg-main-header'>
        <h3 className='h-8 w-full bg-main-header flex px-4 items-center'>
          Endpoints with different content:
        </h3>
        <ul className=''>
          {firstEnvDiffEndpoints.map((item: Endpoint) => (
            <li
              key={item.name}
            >
              <CompareListDifference
                differentItemsInFirstList={firstEnvDiffEndpoints}
                differentItemsInSecondList={secondEnvDiffEndpoints}
                name={item.name}
                artifactType={ArtifactTypes.PLUGIN}
                firstEnvVal={firstEnvVal}
                secondEnvVal={secondEnvVal}
              />
            </li>
          ))}
        </ul>
      </div>
      )}
      { (secondEnvMissingEndpoints.length > 0 || firstEnvMissingEndpoints.length > 0) && (
      <MissingArtifactList
        firstEnvMissingArtifacts={firstEnvMissingEndpoints}
        secondEnvMissingArtifacts={secondEnvMissingEndpoints}
        artifactType={ArtifactTypes.ENDPOINT}
      />
      )}

      <div className='flex flex-row justify-around border border-main-exitbutton'>
        {
            (missingPropertiesInfirstIntegratorFile.length > 0 || missingPropertiesInsecondIntegratorFile.length > 0)
            && (
            <div className='w-full flex flex-col border-r border-main-exitbutton bg-authform-border'>
              <h3 className='h-8 w-full bg-main-header flex px-4 items-center border-b border-main-exitbutton'>
                Properties which not exist in first file:
              </h3>
              <ul className=''>
                {missingPropertiesInfirstIntegratorFile.map((item: string) => (
                  <li
                    key={item}
                    className='h-6'
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            )
          }
        {
            (missingPropertiesInfirstIntegratorFile.length > 0 || missingPropertiesInsecondIntegratorFile.length > 0)
            && (
            <div className='w-full flex flex-col bg-authform-border'>
              <h3 className='h-8 w-full bg-main-header flex px-4 items-center border-b border-main-exitbutton'>
                Properties which not exist in second file:
              </h3>
              <ul className='px-4 py-3'>
                {missingPropertiesInsecondIntegratorFile.map((item: string) => (
                  <li
                    key={item}
                    className='h-6'
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            )
          }
      </div>

      {missingPropertiesInfirstIntegratorFile.length === 0 && missingPropertiesInsecondIntegratorFile.length === 0
        && firstEnvMissingEndpoints.length === 0 && secondEnvMissingEndpoints.length === 0 && firstEnvDiffEndpoints.length === 0
        && secondEnvDiffEndpoints.length === 0 && (
        <h3 className=' flex items-center justify-center h-9 m-6 bg-authform-green'>
          Connectors are identical!
        </h3>
      )}
    </div>
  );
};

export default CompareConnector;
